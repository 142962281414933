import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
    };
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      isMenuOpen: !prevState.isMenuOpen,
    }));
  };

  render() {
    return (
      <div className="relative w-full">
        {/* Büyük Ekranlar için Navbar */}
        <div className="hidden md:flex container w-4/6 mx-auto items-center justify-between h-20 bg-white border-[#E7E7E7] border-b-2">
          {/* Logo */}
          <div className="flex items-center">
            <a href="/"><img src={require('../assets/navBarAcm.png')} alt="Logo" className="h-20 mr-4" /></a>
          </div>

          {/* Menü Öğeleri */}
          <nav className="flex space-x-6">
            <a href="/" className="text-black font-medium text-sm cursor-pointer">Duyurular</a>
            <a href="/bizkimiz" className="text-black font-medium text-sm cursor-pointer">Biz Kimiz</a>
            <a href="/ekip" className="text-black font-medium text-sm cursor-pointer">Ekibimiz</a>
            <a href="/etkin" className="text-black font-medium text-sm cursor-pointer">Etkinliklerimiz</a>
            <a href="/iletisim" className="text-black font-medium text-sm cursor-pointer">İletişim</a>
          </nav>

          {/* Üye Ol Butonu */}
          <a href="/uyeol" className="bg-gradient-to-r from-[#5F2875] to-[#B14BDB] text-white font-medium text-sm py-2 px-4 rounded-md">
Üye Ol
          </a>
        </div>

        {/* Mobil Ekranlar için Navbar */}
        <div className="md:hidden container w-full mx-auto flex items-center justify-between h-20 bg-white border-[#E7E7E7] border-b-2">
          {/* Hamburger Menü */}
          <div className="flex items-center ml-[2.5%] ">
            <button onClick={this.toggleMenu} className="focus:outline-none">
              {this.state.isMenuOpen ? (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              ) : (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              )}
            </button>
          </div>

          {/* Logo */}
          <div className="flex items-center mr-[2.5%]">
            <a href="/"><img src={require('../assets/navBarAcm.png')} alt="Logo" className="h-20" /></a>
          </div>
        </div>

        {/* Mobil Menü Öğeleri */}
        {this.state.isMenuOpen && (
          <div className="md:hidden w-full bg-white border-b border-[#E7E7E7]">
            <nav className="flex flex-col items-center py-4 space-y-2">
              <a href="/" className="text-black font-medium text-sm cursor-pointer">Duyurular</a>
              <a href="/bizkimiz" className="text-black font-medium text-sm cursor-pointer">Biz Kimiz</a>
              <a href="/ekip" className="text-black font-medium text-sm cursor-pointer">Ekibimiz</a>
              <a href="/etkin" className="text-black font-medium text-sm cursor-pointer">Etkinliklerimiz</a>
              <a href="/iletisim" className="text-black font-medium text-sm cursor-pointer">İletişim</a>
              <a href="/uyeol" className="text-black font-medium text-sm cursor-pointer">Üye Ol</a>
            </nav>
          </div>
        )}
      </div>
    );
  }
}
