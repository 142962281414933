import './App.css';
import { Route, Routes, BrowserRouter } from "react-router-dom"

import '@mui/material/styles';
import '@mui/x-date-pickers';

//import Form from './pages/membership';
import Form from './pages/form';
import Events from './pages/events';
import OurTeam from './pages/OurTeam';
import Contact from './pages/contact';
import Home from './pages/home';
import Announcements  from './pages/announcements';
import Whoarewe from './pages/Whoarewe';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HeroSection from './components/Hero';
import Scroll from './components/scroll';


function App() {
  return (
    <div>
      <header>

      <div>
      <Scroll/>
      <Navbar/>
      <BrowserRouter>
      <Routes>

        <Route exact path="/" element={<Home />} />
        <Route exact path="/etkin" element={<Events />} />
        <Route exact path="/ekip" element={<OurTeam />} />
        <Route exact path="/iletisim" element={<Contact />} />
        <Route exact path="/duyurular" element={<Announcements />} />
        <Route exact path="/bizkimiz" element={<Whoarewe />} />
        <Route exact path="/uyeol" element={<Form />} />

      </Routes>
      </BrowserRouter>

      <Footer/>

       
        </div>
   
      </header>
    </div>
  );
}

export default App;
