import React, { useState } from "react";
import DateFnsUtils from '@date-io/date-fns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
    Grid,
    Container,
    TextField,
    MenuItem,
    StyledEngineProvider,
    FormControlLabel,
    Checkbox,
    Button
} from '@mui/material';
import "../styles/form.css";
import Pdf from "../assets/aydinlatma.pdf";
import { collection, addDoc } from "firebase/firestore";
import { db } from '../firebase';
import '@mui/material/styles';
import '@mui/x-date-pickers';
import { CheckCircle, CheckCircleOutline, Circle, CircleOutlined } from "@mui/icons-material";
import HeroSection from "../components/Hero";
import photo from "../assets/form.svg";

function Form() {
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [schoolNo, setSchoolNo] = useState("")
    const [email, setEmail] = useState("")
    const [birthday, setBirthday] = useState(null)
    const [faculty, setFaculty] = useState("")
    const [classLevel, setClassLevel] = useState("")
    const [address, setAddress] = useState("")
    const [coordi1, setCoordi1] = useState("")
    const [coordi2, setCoordi2] = useState("")
    const [whyAcm, setWhyAcm] = useState("")
    const [whereSeen, setWhereSeen] = useState("")
    const [isActive, setIsActive] = useState(false)
    const [isWhatsapp, setIsWhatsapp] = useState(false)
    const [isPromotion, setIsPromotion] = useState(false)
    const [isAccepted, setIsAccepted] = useState(false)

    const [isClicked, setIsClicked] = useState(false)
    const [isFocused, setIsFocused] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        //Password check
        try {
            if (!isClicked) {
                setIsClicked(true);
                console.log(coordi1)
                const docRef = await addDoc(collection(db, "oryantasyon"), {
                    name: name,
                    phone: phone,
                    schoolNo: schoolNo,
                    email: email,
                    birthday: birthday.toString(),
                    faculty: faculty,
                    classLevel: classLevel,
                    address: address,
                    coordi1: coordi1,
                    coordi2: coordi2,
                    whyAcm: whyAcm,
                    whereSeen: whereSeen,
                    isActive: isActive,
                    isWhatsapp: isWhatsapp,
                    isPromotion: isPromotion,
                    createdAt: new Date().getTime(),
                });
                if (docRef != null) {
                    console.log("Document written with ID: ", docRef.id);
                    setName("");
                    setPhone("");
                    setSchoolNo("");
                    setEmail("");
                    setFaculty("");
                    setClassLevel("");
                    setAddress("");
                    setCoordi1("");
                    setCoordi2("");
                    setWhyAcm("");
                    setWhereSeen("");
                    setIsAccepted(false);
                    setIsActive(false);
                    setIsPromotion(false);
                    setIsWhatsapp(false);
                    setBirthday(null);
                    alert("Formunuz başarıyla gönderilmiştir.")
                    setIsClicked(false);
                }
            }
        } catch (e) {
            console.log(e);
            console.error("Error adding document: ", e);
            alert("Form gönderilemedi lütfen tekrar deneyin.");
            setIsClicked(false);
        }
    };
    return (
        <div >
            <HeroSection title="BİZE KATIL" p="ACM Hacettepe" photo={photo} />
        <StyledEngineProvider injectFirst>
            <Container maxWidth="md" >
                <form onSubmit={handleSubmit}>
                    <Grid
                        width={'90vw'}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid
                                item
                            >
                                <TextField
                                    fullWidth
                                    //fullWidth={true}
                                    color="primary"
                                    label="Ad Soyad"
                                    autoComplete="Name"
                                    variant="standard"
                                    required
                                    value={name}
                                    inputProps={{ style: { fontSize: 22, marginTop: 7, marginBottom: -8 } }}
                                    InputLabelProps={{ style: { fontSize: 28, marginTop: -2 } }}
                                    onChange={e => setName(e.target.value)}
                                    sx={{
                                        maxWidth: '90vw',
                                        borderBlockWidth: '2px',
                                        //borderBottom: 1,
                                        //fontSize: '28px',
                                        //fontWeight: '600',
                                        input: { color: 'black' },  // Changes the input text color
                                        label: { color: '#1B1C1E', '&.Mui-focused': { color: '#B14BDB' } }, // Changes the label text color
                                        '& .MuiInput-underline:before': { borderBottomColor: '#1B1C1E', borderBottomWidth: '2px' },  // Changes the underline color
                                        '& .MuiInput-underline:after': { borderBottomColor: '#B14BDB', borderBottom: '2px' }, // Changes the underline after focus
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                            >
                                <TextField
                                    fullWidth
                                    label="Telefon Numarası"
                                    autoComplete="phone"
                                    variant="standard"
                                    placeholder={isFocused ? "05551112233" : ""}
                                    InputLabelProps={{ shrink: isFocused || phone !== "", style: { fontSize: 28, marginTop: -2 } }} // Shrink label if
                                    required
                                    value={phone}
                                    inputProps={{ style: { fontSize: 22, marginTop: 7, marginBottom: -8 } }}
                                    //InputLabelProps={{style: {fontSize: 28, marginTop: -2}}}
                                    onChange={e => setPhone(e.target.value)}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)}
                                    sx={{
                                        maxWidth: '90vw',
                                        //borderBottom: 1,
                                        fontSize: '28px',
                                        input: { color: 'black' },  // Changes the input text color
                                        label: { color: '#1B1C1E', '&.Mui-focused': { color: '#B14BDB' } }, // Changes the label text color
                                        '& .MuiInput-underline:before': { borderBottomColor: '#1B1C1E', borderBottomWidth: '2px' },  // Changes the underline color
                                        '& .MuiInput-underline:after': { borderBottomColor: '#B14BDB', borderBottom: '2px' },  // Changes the underline after focus
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            width={"100%"}
                        >
                            <Grid item>
                                <TextField
                                    label="Okul No"
                                    variant="standard"
                                    required
                                    value={schoolNo}
                                    inputProps={{ style: { fontSize: 22, marginTop: 7, marginBottom: -8 } }}
                                    InputLabelProps={{ style: { fontSize: 28, marginTop: -2 } }}
                                    onChange={e => setSchoolNo(e.target.value)}
                                    sx={{
                                        maxWidth: '90vw',

                                        //borderBottom: 1,
                                        fontSize: '28px',
                                        input: { color: 'black' },  // Changes the input text color
                                        label: { color: '#1B1C1E', '&.Mui-focused': { color: '#B14BDB' } }, // Changes the label text color
                                        '& .MuiInput-underline:before': { borderBottomColor: '#1B1C1E', borderBottomWidth: '2px' },  // Changes the underline color
                                        '& .MuiInput-underline:after': { borderBottomColor: '#B14BDB', borderBottom: '2px' }, // Changes the underline after focus
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label="Email"
                                    autoComplete="Email"
                                    variant="standard"
                                    type="email"
                                    required
                                    value={email}
                                    inputProps={{ style: { fontSize: 22, marginTop: 7, marginBottom: -8 } }}
                                    InputLabelProps={{ style: { fontSize: 28, marginTop: -2 } }}
                                    onChange={e => setEmail(e.target.value)}
                                    sx={{
                                        maxWidth: '90vw',

                                        //borderBottom: 1,
                                        fontSize: '28px',
                                        input: { color: 'black' },  // Changes the input text color
                                        label: { color: '#1B1C1E', '&.Mui-focused': { color: '#B14BDB' } }, // Changes the label text color
                                        '& .MuiInput-underline:before': { borderBottomColor: '#1B1C1E', borderBottomWidth: '2px' },  // Changes the underline color
                                        '& .MuiInput-underline:after': { borderBottomColor: '#B14BDB', borderBottom: '2px' }, // Changes the underline after focus
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            width={"100%"}
                        >
                            <Grid item>
                                <TextField
                                    label="Bölüm"
                                    variant="standard"
                                    required
                                    value={faculty}
                                    inputProps={{ style: { fontSize: 22, marginTop: 7, marginBottom: -8 } }}
                                    InputLabelProps={{ style: { fontSize: 28, marginTop: -2 } }}
                                    onChange={e => setFaculty(e.target.value)}
                                    sx={{
                                        maxWidth: '90vw',

                                        //borderBottom: 1,
                                        fontSize: '28px',
                                        input: { color: 'black' },  // Changes the input text color
                                        label: { color: '#1B1C1E', '&.Mui-focused': { color: '#B14BDB' } }, // Changes the label text color
                                        '& .MuiInput-underline:before': { borderBottomColor: '#1B1C1E', borderBottomWidth: '2px' },  // Changes the underline color
                                        '& .MuiInput-underline:after': { borderBottomColor: '#B14BDB', borderBottom: '2px' },  // Changes the underline after focus
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label="Sınıf"
                                    variant="standard"
                                    required
                                    value={classLevel}
                                    inputProps={{ style: { fontSize: 22, marginTop: 7, marginBottom: -8 } }}
                                    InputLabelProps={{ style: { fontSize: 28, marginTop: -2 } }}
                                    onChange={e => setClassLevel(e.target.value)}
                                    sx={{
                                        maxWidth: '90vw',

                                        //borderBottom: 1,
                                        fontSize: '28px',
                                        input: { color: 'black' },  // Changes the input text color
                                        label: { color: '#1B1C1E', '&.Mui-focused': { color: '#B14BDB' } }, // Changes the label text color
                                        '& .MuiInput-underline:before': { borderBottomColor: '#1B1C1E', borderBottomWidth: '2px' },  // Changes the underline color
                                        '& .MuiInput-underline:after': { borderBottomColor: '#B14BDB', borderBottom: '2px' },  // Changes the underline after focus
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="baseline"
                        >
                            <Grid item
                                sx={{

                                    //fontSize: '28px',
                                    input: { color: 'black' },  // Changes the input text color
                                    label: { color: '#1B1C1E', '&.Mui-focused': { color: '#B14BDB' } }, // Changes the label text color
                                    '& .MuiInput-underline:before': { borderBottomColor: '#1B1C1E', borderBottomWidth: '2px' },  // Changes the underline color
                                    '& .MuiInput-underline:after': { borderBottomColor: '#B14BDB', borderBottom: '2px' }, // Changes the underline after focus
                                }}>
                                <LocalizationProvider dateAdapter={AdapterMoment} >
                                    <DatePicker
                                        margin="normal"
                                        required
                                        label="Doğum Tarihi"
                                        inputProps={{ style: { fontSize: 22, marginTop: 7, marginBottom: -8 } }}
                                        //InputLabelProps={{style: {fontSize: 28, marginTop: -2}}}
                                        InputLabelProps={{ shrink: true }} // Shrink label if
                                        inputFormat="DD/MM/YYYY"
                                        value={birthday}
                                        onChange={e => setBirthday(e)}
                                        variant="responsive"
                                        renderInput={(params) => <TextField variant="standard" {...params} sx={{ maxWidth: '90vw', }} InputLabelProps={{ style: { fontSize: 28, marginTop: window.innerWidth > 1100 ? -2 : -10 } }} />}

                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item >
                                <TextField
                                    label="Kaldığı Yer"
                                    variant="standard"
                                    required
                                    select
                                    value={address}
                                    SelectProps={{ style: { fontSize: 22, marginTop: 31, marginBottom: -2, height: 24.5 } }}
                                    InputLabelProps={{ style: { fontSize: 28, marginTop: -2 } }}
                                    onChange={e => setAddress(e.target.value)}
                                    sx={{
                                        maxWidth: '90vw',

                                        //borderBottom: 1,
                                        fontSize: '28px',
                                        input: { color: 'black' },  // Changes the input text color
                                        label: { color: '#1B1C1E', '&.Mui-focused': { color: '#B14BDB' } }, // Changes the label text color
                                        '& .MuiInput-underline:before': { borderBottomColor: '#1B1C1E', borderBottomWidth: '2px' },  // Changes the underline color
                                        '& .MuiInput-underline:after': { borderBottomColor: '#B14BDB', borderBottom: '2px' }, // Changes the underline after focus
                                    }}
                                    helperText="Örgün Eğitim Sırasında Adres">

                                    <MenuItem value={"Belli Değil"} >Belli Değil</MenuItem>
                                    <MenuItem value={"Kampüs İçi"}>Kampüs İçi</MenuItem>
                                    <MenuItem value={"Kampüs Dışı"}>Kampüs Dışı</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="baseline"
                        >
                            <Grid item>
                                <TextField
                                    label="1. Tercih"
                                    variant="standard"
                                    required
                                    select
                                    value={coordi1}
                                    SelectProps={{ style: { fontSize: 22, marginTop: 31, marginBottom: -2, height: 24.5 } }}
                                    InputLabelProps={{ style: { fontSize: 28, marginTop: -2 } }}
                                    sx={{
                                        maxWidth: '90vw',

                                        //borderBottom: 1,
                                        fontSize: '28px',
                                        input: { color: 'black' },  // Changes the input text color
                                        label: { color: '#1B1C1E', '&.Mui-focused': { color: '#B14BDB' } }, // Changes the label text color
                                        '& .MuiInput-underline:before': { borderBottomColor: '#1B1C1E', borderBottomWidth: '2px' },  // Changes the underline color
                                        '& .MuiInput-underline:after': { borderBottomColor: '#B14BDB', borderBottom: '2px' }, // Changes the underline after focus
                                    }}
                                    onChange={e => setCoordi1(e.target.value)}>
                                    <MenuItem value={"TEK"}>Teknik Etkinlik Koordinatörlüğü</MenuItem>
                                    <MenuItem value={"SEK"}>Sosyal Etkinlik Koordinatörlüğü</MenuItem>
                                    <MenuItem value={"HİK"}>Halkla İlişkiler Koordinatörlüğü</MenuItem>
                                    <MenuItem value={"KİK"}>Kurumsal İlişkiler Koordinatörlüğü</MenuItem>
                                    <MenuItem value={"RT"}>Reklam Tanıtım Koordinatörlüğü</MenuItem>
                                    <MenuItem value={"AR-GE"} disabled sx={{ 
                                                                        whiteSpace: 'normal', 
                                                                        overflowWrap: 'break-word',
                                                                        display: 'block',
                                                                        '@media (max-width: 600px)': {
                                                                            fontSize: '18px',
                                                                            lineHeight: '1.5em',
                                                                        }
                                    }}>Ar-Ge Birimi kendi sitesinden başvuruyla alım yapmaktadır</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item>
                                <TextField
                                    label="2. Tercih"
                                    variant="standard"
                                    required
                                    select
                                    value={coordi2}
                                    SelectProps={{ style: { fontSize: 22, marginTop: 31, marginBottom: -2, height: 24.5 } }}
                                    InputLabelProps={{ style: { fontSize: 28, marginTop: -2 } }}
                                    sx={{
                                        maxWidth: '90vw',

                                        //borderBottom: 1,
                                        fontSize: '28px',
                                        input: { color: 'black' },  // Changes the input text color
                                        label: { color: '#1B1C1E', '&.Mui-focused': { color: '#B14BDB' } }, // Changes the label text color
                                        '& .MuiInput-underline:before': { borderBottomColor: '#1B1C1E', borderBottomWidth: '2px' },  // Changes the underline color
                                        '& .MuiInput-underline:after': { borderBottomColor: '#B14BDB', borderBottom: '2px' },  // Changes the underline after focus
                                    }}
                                    onChange={e => setCoordi2(e.target.value)}>
                                    <MenuItem value={"TEK"}>Teknik Etkinlik Koordinatörlüğü</MenuItem>
                                    <MenuItem value={"SEK"}>Sosyal Etkinlik Koordinatörlüğü</MenuItem>
                                    <MenuItem value={"HİK"}>Halkla İlişkiler Koordinatörlüğü</MenuItem>
                                    <MenuItem value={"KİK"}>Kurumsal İlişkiler Koordinatörlüğü</MenuItem>
                                    <MenuItem value={"RT"}>Reklam Tanıtım Koordinatörlüğü</MenuItem>
                                    <MenuItem value={"AR-GE"} disabled sx={{ 
                                                                        whiteSpace: 'normal', 
                                                                        overflowWrap: 'break-word',
                                                                        display: 'block',
                                                                        '@media (max-width: 600px)': {
                                                                            fontSize: '18px',
                                                                            lineHeight: '1.5em',
                                                                        }
                                    }}>Ar-Ge Birimi kendi sitesinden başvuruyla alım yapmaktadır</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="end"
                        >
                            <Grid item>
                                <TextField
                                    label="Bizden nasıl haberdar oldunuz?"
                                    variant="standard"
                                    required
                                    select
                                    value={whereSeen}
                                    SelectProps={{ style: { fontSize: 22, marginTop: 31, marginBottom: window.innerWidth > 1100 ? 42.5 : 0, height: 24.5 } }}
                                    InputLabelProps={{ style: { fontSize: 28, marginTop: -2 } }}
                                    sx={{
                                        maxWidth: '90vw',
                                        //borderBottom: 1,
                                        fontSize: '28px',
                                        input: { color: 'black' },  // Changes the input text color
                                        label: { color: '#1B1C1E', '&.Mui-focused': { color: '#B14BDB' } }, // Changes the label text color
                                        '& .MuiInput-underline:before': { borderBottomColor: '#1B1C1E', borderBottomWidth: '2px' },  // Changes the underline color
                                        '& .MuiInput-underline:after': { borderBottomColor: '#B14BDB' },  // Changes the underline after focus
                                    }}
                                    onChange={e => setWhereSeen(e.target.value)}>
                                    <MenuItem value={"Sosyal Medya"}>Sosyal Medya</MenuItem>
                                    <MenuItem value={"Kampüs İçi Tanıtım"}>Kampüs İçi Tanıtım</MenuItem>
                                    <MenuItem value={"Whatsapp Grupları"}>Whatsapp Grupları</MenuItem>
                                    <MenuItem value={"Geçmiş Etkinlikler"}>Geçmiş Etkinlikler</MenuItem>
                                    <MenuItem value={"Arkadaş"}>Arkadaş</MenuItem>
                                    <MenuItem value={"Diğer"}>Diğer</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item>
                                <TextField
                                    className="text-field"
                                    //helperText="Neden bu koordinatörlükleri tercih ettiniz"
                                    disabled={!coordi1 && !coordi2}
                                    label={(!coordi1 && !coordi2) ? "Lütfen en az 1 koordinatörlük seçiniz." : ("Neden " + ((!coordi1 || !coordi2) ? (coordi1 ? coordi1 : coordi2) : (coordi1 + " ve " + coordi2)) + " ?")}
                                    variant="standard"
                                    helperText="Neden bu koordinatörlükleri seçtiniz, bu kordinatörlüklerle ilgili en çok ilginizi çeken ne oldu?"
                                    multiline
                                    value={whyAcm}
                                    inputProps={{ style: { fontSize: 22, marginTop: 7, marginBottom: -8 } }}
                                    InputLabelProps={{ style: { fontSize: 28, marginTop: -7 } }}
                                    sx={{
                                        maxWidth: '90vw',
                                        //fontSize: '28px',
                                        input: { color: 'black' },  // Changes the input text color
                                        label: { color: '#1B1C1E', '&.Mui-focused': { color: '#B14BDB' } }, // Changes the label text color
                                        '& .MuiInput-underline:before': { borderBottomColor: '#1B1C1E', borderBottomWidth: '2px', borderStyle: "solid" },  // Changes the underline color
                                        '& .MuiInput-underline:after': { borderBottomColor: '#B14BDB' },  // Changes the underline after focus
                                    }}
                                    onChange={e => setWhyAcm(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >

                            <Grid
                                item
                                sx={{
                                    margin: '8px',
                                    overflow: "hidden"
                                }}
                            >
                                <div className="w-[500px]">

                                    <Container>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                        // alignItems="center"
                                        >
                                            <Grid item sx={{ marginLeft: '-10px' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={<CircleOutlined />}
                                                            checkedIcon={<CheckCircle />}
                                                            checked={isActive}
                                                            onChange={e => setIsActive(!isActive)}

                                                            sx={{

                                                                color: '#B14BDB',  // Custom color for the box
                                                                '&.Mui-checked': {
                                                                    color: '#7711B6',  // Custom color for the checked state
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    sx={{ maxWidth: '80vw' }}
                                                    label="Aktif üye olmak istiyorum."
                                                />
                                            </Grid>
                                            <Grid item sx={{ marginLeft: '-10px' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={<CircleOutlined />}
                                                            checkedIcon={<CheckCircle />}
                                                            checked={isWhatsapp}
                                                            onChange={e => setIsWhatsapp(!isWhatsapp)}
                                                            sx={{

                                                                color: '#B14BDB',  // Custom color for the box
                                                                '&.Mui-checked': {
                                                                    color: '#7711B6',  // Custom color for the checked state
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    sx={{ maxWidth: '80vw' }}

                                                    label="Whatsapp ACM Hacettepe gruplarına eklenmek istiyorum."
                                                />
                                            </Grid>
                                            <Grid item sx={{ marginLeft: '-10px' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={<CircleOutlined />}
                                                            checkedIcon={<CheckCircle />}
                                                            checked={isPromotion}
                                                            onChange={e => setIsPromotion(!isPromotion)}
                                                            sx={{

                                                                color: '#B14BDB',  // Custom color for the box
                                                                '&.Mui-checked': {
                                                                    color: '#7711B6',  // Custom color for the checked state
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    sx={{ maxWidth: '80vw' }}

                                                    label="Duyuru veya yenilikleri SMS ve E-posta yoluyla almak istiyorum."
                                                />
                                            </Grid>
                                            <Grid item sx={{ marginLeft: '-10px' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={<CircleOutlined />}
                                                            checkedIcon={<CheckCircle />}
                                                            checked={isAccepted}
                                                            required
                                                            onChange={e => setIsAccepted(!isAccepted)}
                                                            sx={{

                                                                color: '#B14BDB',  // Custom color for the box
                                                                '&.Mui-checked': {
                                                                    color: '#7711B6',  // Custom color for the checked state
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    sx={{ maxWidth: '80vw' }}
                                                    z
                                                    label={<><a href={Pdf} target="_blank" rel="noopener noreferrer">Aydınlatma metnini</a> okudum ve onaylıyorum.</>}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </div>
                            </Grid>
                            <Grid
                                item
                                height={{ sm: 'auto', md: '200px' }}
                            >
                                <div className="flex justify-center md:h-full">
                                    <button
                                        type="submit"
                                        style={{ background: "linear-gradient(90.98deg, #B14BDB -1.23%, #7711B6 101.07%) " }}
                                        className=" mx-[24px] md:mx-[8px] text-4xl font-medium  px-6 py-3 w-[90vw] md:w-[500px]  bg-purple-700 text-white rounded-md shadow-sm hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 h-full"
                                    >
                                        Kaydol
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                </form>
            </Container>
        </StyledEngineProvider >
    
        </div>
    );

}

export default Form;
