import React, { Component } from 'react';
import { FaLinkedin, FaTwitter, FaInstagram, FaFacebook, FaYoutube } from 'react-icons/fa'; // React Icons import
//import Xf from 'react-icons'
import navBarAcm from '../assets/navBarAcm.png'; // Resim dosyasının yolunu buraya ekleyin
import twitter from "../assets/icons8-twitterx.svg"

export default class Footer extends Component {
  render() {
    return (
      <footer className="w-full flex flex-col items-center p-4 bg-white mt-auto">
              <br/>
      <br/>
    
        {/* Divider */}
        <div className="w-4/6 h-[1px] bg-[#E4E7EC]" />
        
        {/* ACM Logo */}
        <div className="flex justify-center">
          <img
            src={navBarAcm}
            alt="ACM Hacettepe"
            className="w-36 h-20 object-contain"
          />
        </div>

        {/* Iconlar ve ACM yazısı aynı hizada */}
        <div className="flex justify-center items-center w-full max-w-screen-xl py-4">
          {/* Social Icons */}
          <div className="flex gap-4">
           <a href='https://www.linkedin.com/company/acm-hacettepe/' target='_blank'><FaLinkedin className="w-6 h-6 text-[#98A2B3]" /></a>  {/* LinkedIn Icon */}
           <a href='https://x.com/acmhacettepe' target='_blank'> <FaTwitter className="w-6 h-6 text-[#98A2B3]" />   </a>{/* Twitter Icon */}
           <a href='https://www.instagram.com/acmhacettepe/' target='_blank'> <FaInstagram className="w-6 h-6 text-[#98A2B3]" /> </a>{/* Instagram Icon */}
           <a href='https://www.facebook.com/acmhacettepe' target='_blank'> <FaFacebook className="w-6 h-6 text-[#98A2B3]" />  </a> {/* Facebook Icon */}
           <a href='https://www.youtube.com/@ACMHACETTEPE' target='_blank'> <FaYoutube className="w-6 h-6 text-[#98A2B3]" />   </a>{/* YouTube Icon */}
          </div>

          {/* Footer Text */}
          <div className="text-[#98A2B3] text-sm pl-[6%]">
            © 2024 ACM Hacettepe AR-GE Birimi
          </div>
        </div>
      </footer>
    );
  }
}