import React from 'react';
import { Link } from 'react-router-dom';

const LinkWithIcon = ({ link, imgSrc, altText, className }) => {
  if (!link) return null;
  return (
    <Link to={link} className={className}>
      <img src={imgSrc} alt={altText} />
    </Link>
  );
};

const LinkComponent = ({ link, imgSrc, altText}) => {


    return (
      <a href={link} >
        <img src={imgSrc} alt={altText} />
      </a>
    
  );
};

export default LinkComponent;

{/*
      <div>
      <LinkWithIcon 
        link= {link}
        imgSrc={imgSrc} 
        altText="Ok Simgesi" 
        className="text-link" 
      />
    </div>
  */}