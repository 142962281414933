import React, { useState, useEffect } from 'react';
import '../styles/events.css';


const ImageCarousel = ({ images = [] }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);

  useEffect(() => {
    if (images.length === 0) return; // Eğer images dizisi boşsa işlem yapma
    const interval = setInterval(() => {
      setIsSliding(true);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
        setIsSliding(false);
      }, 1000);
    }, 10000);
    return () => clearInterval(interval);
  }, [images.length]);

  if (images.length === 0) {
    return <div>No images to display</div>; // Resim yoksa gösterilecek mesaj
  }

  const goToNextSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  
  return (
  
    <div className="image-carousel">
      <div
        className={`carousel-image-container ${isSliding ? 'sliding' : ''}`}
        style={{
          transform: `translateX(-${currentImageIndex * 100}%)`,
        }}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index}`}
            className="carousel-image"
          />
        ))}
       </div>
      
      <button className="prev-button" onClick={goToPrevSlide}>❮</button>
      <button className="next-button" onClick={goToNextSlide}>❯</button>
    </div>

  );
};

export default ImageCarousel;