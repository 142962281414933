import React, { useEffect } from 'react';


const Map = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1082.6333035500768!2d32.73380381900892!3d39.87089124424113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d347781f38ddf7%3A0x2bf74cd3012193d2!2sHacettepe%20%C3%9Cniversitesi!5e0!3m2!1str!2str!4v1726000289185!5m2!1str!2str"
        width="600"
        height="400"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};


export default Map;