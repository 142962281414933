
import React, { Component } from 'react';
import HeroSection from '../components/Hero';
import ImageCarousel from '../components/ImageCarousel';
import TextComponent from '../components/TextComponent';
import '../styles/events.css';
import LinkComponent from '../components/LinkComponent';
import photo from '../assets/events.svg';

class Events extends Component {
    
  render() {
    const images_acs = [
        `${process.env.PUBLIC_URL}/acs-ml.png`,
        `${process.env.PUBLIC_URL}/acs1.png`,
        `${process.env.PUBLIC_URL}/acs2.png`,
        `${process.env.PUBLIC_URL}/acs3.png`,
      ];
      const images_fasil = [
        `${process.env.PUBLIC_URL}/fasil1.jpg`,
        `${process.env.PUBLIC_URL}/fasil2.jpg`,
        `${process.env.PUBLIC_URL}/fasil3.jpg`,
        `${process.env.PUBLIC_URL}/fasil16.jpg`,
      ];
      const images_gelisim = [
        `${process.env.PUBLIC_URL}/gelisim-bob.jpg`,
        `${process.env.PUBLIC_URL}/gelisim-tunc-kurt.jpg`,
        `${process.env.PUBLIC_URL}/gelisim1.jpg`,
        `${process.env.PUBLIC_URL}/gelisim2.jpg`,
        `${process.env.PUBLIC_URL}/gelisim3.jpg`,
      ];
      const images_hujam = [
        `${process.env.PUBLIC_URL}/hujam1.png`,
      ];
      const images_huprog = [
        `${process.env.PUBLIC_URL}/huprog1.jpg`,
        `${process.env.PUBLIC_URL}/huprog2.jpg`,
        `${process.env.PUBLIC_URL}/huprog3.jpg`,
        `${process.env.PUBLIC_URL}/huprog19.jpg`,
        `${process.env.PUBLIC_URL}/huprog20.jpg`,
        `${process.env.PUBLIC_URL}/huprog21.jpg`,
      ];
      const images_Liderlik_kampi = [
        `${process.env.PUBLIC_URL}/lk1.jpg`,
        `${process.env.PUBLIC_URL}/lk2.jpg`,
        `${process.env.PUBLIC_URL}/lk3.jpg`,
        `${process.env.PUBLIC_URL}/lk22.jpg`,
        `${process.env.PUBLIC_URL}/lk21.jpg`,
      ];
      const images_mg = [
        `${process.env.PUBLIC_URL}/mg1.jpg`,
        `${process.env.PUBLIC_URL}/mg2.jpg`,
        `${process.env.PUBLIC_URL}/mg3.jpg`,
        `${process.env.PUBLIC_URL}/mg-canozan.jpg`,
        `${process.env.PUBLIC_URL}/mg-noluyoya.jpg`,
      ];
    
      const images_sms = [
        `${process.env.PUBLIC_URL}/sms1.png`,
        `${process.env.PUBLIC_URL}/sms2.png`,
        `${process.env.PUBLIC_URL}/sms3.png`,
        `${process.env.PUBLIC_URL}/sms19.png`,
        `${process.env.PUBLIC_URL}/sms22.png`,
      ];
    
      const images_syn = [
        `${process.env.PUBLIC_URL}/syn1.png`,
        `${process.env.PUBLIC_URL}/syn2.png`,
        `${process.env.PUBLIC_URL}/syn21-22.png`,
        `${process.env.PUBLIC_URL}/syn22-23.png`,
        `${process.env.PUBLIC_URL}/syn23-24.jpg`,
      ];
    
      const images_teknik_gezi = [
        `${process.env.PUBLIC_URL}/teknik-gezi1.jpg`,
        `${process.env.PUBLIC_URL}/teknik-gezi2.jpg`,
        `${process.env.PUBLIC_URL}/teknik-gezi3.jpg`,
      ];
    
      const images_teknik = [
        `${process.env.PUBLIC_URL}/teknik1.jpg`,
        `${process.env.PUBLIC_URL}/teknik2.jpg`,
      ];
    return (
      <div>     
          <HeroSection title="ETKİNLİKLERİMİZ" p="ACM Hacettepe" photo={photo}/>
      <div className='event overflow-hidden'>
        <div className="main-component">
            <h1 className='baslik'> SENİN YERİN NERESİ? </h1>
          <ImageCarousel images={images_syn}  />
          <TextComponent 
            title="SENİN YERİN NERESİ?" 
            description="ACM Hacettepe topluluğunun yönetim kurulu ekibini, koordinatörlükleri ve bu koordinatörlüklerin neler yaptığını, etkinliklerini tanıtan, yılın ilk etkinliğidir."
          />
        </div>
        <div  className="text-link-reverse"  >
         <LinkComponent  
          imgSrc={`${process.env.PUBLIC_URL}/ok_resmi.jpeg`} 

          altText="Ok Simgesi" 
          link="https://youtu.be/AeVU2uMRWhQ?si=e8_i5DX6UyECT4-l" 
          >
          </LinkComponent>
        </div>
        <div className="main-component reverse">
        <h1 className='baslik'> FASIL </h1>
         <ImageCarousel images={images_fasil}  />
         <TextComponent 
            title="FASIL"
            description="ACM Hacettepe ailesi olarak eski-yeni yönetim kurulunu ve üyelerini bir araya getirip, topluluğun doğum gününü kutladığı gelenekselleşmiş bir etkinliktir. Bu etkinlik yönetim kurulu ve üyeler arasında bir köprü kurulmasını ve üyelerin berbaber eğlenmelerini hedefler."
          />
        </div>

        <div className="main-component">
        <h1 className='baslik'> MOR GEYİK </h1>
        <ImageCarousel images={images_mg}  />
          <TextComponent 
            title="MOR GEYİK" 
            description="Türkiye'nin en saygın üniversitelerinden Hacettepe Üniversitesi'nin Beytepe Kampüsü'nde ACM Hacettepe Öğrenci Topluluğu tarafından ilki 2017'de gerçekleştirilen Mor Geyik, öğrencileri sektörün farklı alanlarında tanınmış ve başarı kazanmış insanlarla samimi bir sohbet havasında bir araya getirmeyi amaçlayan bir etkinliktir. Bu etkinlikte öğrenciler katılımcılarımız ile özel bir bağ kurarak onların hayat tecrübelerini ve tavsiyelerini dinlerken aynı zamanda akıllarındaki sorulara cevap bulma fırsatını yakalayabilecekler."
          />
        </div>
        <div  className="text-link-reverse"  >
         <LinkComponent  
          imgSrc={`${process.env.PUBLIC_URL}/ok_resmi.jpeg`} 
          altText="Ok Simgesi"  

          link="https://www.instagram.com/p/C_tN1aMN-PJ/?igsh=MXIyNWtmem10M3J3dA=="

          >
          </LinkComponent>
        </div>
        <div className="main-component reverse">
            <h1 className='baslik'> HUJAM </h1>
          <ImageCarousel images={images_hujam}  />
          <TextComponent 
            title="HUJAM"
            description="Hacettepe Üniversitesi Game Jam İlkini 2021’de düzenlediğimiz HUJAM, başta Hacettepe Üniversitesi öğrencileri başta olmak üzere oyun geliştirme alanına ilgi duyan, kendisini ve networkünü geliştirmek isteyen bütün öğrenciler için düzenlenen 72 saatlik bir oyun yapma yarışmasıdır. Yarışmacılar, etkinlik başlangıcında verilen temaya uygun olarak bireysel veya takım halinde oyunlarını geliştirmektedir. Jam bitiminde yarışmacıların oyunları yine yarışmacılar arasında ön eleme oylamasına tabi tutulmaktadır. Ön elemeyi başarıyla geçen oyunlar, jürilerin oylaması sonucunda açıklanmakta ve dereceye giren takımlar ödüllendirilmektedir."
          />
        </div>
        <div  className="text-link"  >
         <LinkComponent 
          link="https://hujam.acmhacettepe.com/"  
          imgSrc={`${process.env.PUBLIC_URL}/ok_resmi.jpeg`} 
          altText="Ok Simgesi"  
          >
          </LinkComponent>
        </div>
        <div className="main-component">
        <h1 className='baslik'> GELİŞİM </h1>
          <ImageCarousel images={images_gelisim}  />
          <TextComponent 
            title="GELİŞİM" 
            description="Gelişim etkinliği ile Hacettepe Üniversitesi öğrencilerinin kişisel olarak güçlenmelerini, mesleki açıdan farkındalıklarının artmasını, sosyalleşmelerine olanak sağlamayı ve değerli konuşmacılarımızın ilham veren kariyerleri doğrultusunda öğrencilerin kendi kariyerlerine yön vermesini hedefliyoruz."
          />
        </div>
        <div  className="text-link-reverse"  >
         <LinkComponent 
            link={"https://gelisim.acmhacettepe.com/"} 
            imgSrc={`${process.env.PUBLIC_URL}/ok_resmi.jpeg`} 
            altText="Ok Simgesi"  
          >
          </LinkComponent>
        </div>
        <div className="main-component reverse">
        <h1 className='baslik'> HUPROG </h1>
        <ImageCarousel images={images_huprog}  />
          <TextComponent 
            title="HUPROG"
            description="HUPROG (Hacettepe Üniversitesi Programlama Yarışması); Türkiye'deki tüm öğrencilere açık olan ve finalinin 15 Nisan tarihinde, Hacettepe Üniversitesi Beytepe Kampüsü'nde gerçekleştirilmesi planlanan bir programlama yarışmasıdır. İlkini 2016'da gerçekleştirdiğimiz ve başarıyla sonuçlandırdığımız etkinliğimizin bu sene; çapını ve süresini artırıp daha geniş kitlelere ulaşmayı hedefliyoruz. Yarışma, algoleague ortamı üzerinde gerçekleştirilip; katılım takımlar halinde olacaktır. Bireysel katılımlar, tek kişilik takımlar gibi değerlendirilecektir.Takımlar maksimum 3 kişilik olup yarışmaya katılacak takımlar, yaptığımız ön elemeyle belirlenecektir ve eleme sonucunda ilk 15 sırada bulunan takımlara yarışmaya katılma hakkı tanınacaktır."
          />
        </div>
        <div  className="text-link"  >
         <LinkComponent 
          link={"https://huprog.acmhacettepe.com/"}  
          imgSrc={`${process.env.PUBLIC_URL}/ok_resmi.jpeg`} 
          altText="Ok Simgesi"  
          >
          </LinkComponent>
        </div>
        <div className="main-component">
        <h1 className='baslik'> SMS </h1>
        <ImageCarousel images={images_sms}/>
          <TextComponent 
            title="SMS" 
            description="Sosyal Medya Söyleşileri Sosyal medyanın gücünün ve etkisinin ön planda olduğu bu etkinlikte sosyal medyanın şirketler için önemi, günlük hayatımızda sosyal medyanın yeri, sosyal medyayı etkili kullanmak gibi genel konular yer almaktadır. Konuşmacıların, kendilerinin veya içinde bulundukları oluşumların adına şu anki konumlarına nasıl geldiklerini, karşılaştıkları güçlükleri, eğlenceli anılarını ve kazandıkları başarıları bizlerle paylaşacağı sohbet tadında bir söyleşi etkinliğidir."
          />
        </div>
        <div  className="text-link-reverse"  >
         <LinkComponent 
          link={"https://sms.acmhacettepe.com/"} 
          imgSrc={`${process.env.PUBLIC_URL}/ok_resmi.jpeg`} 
          altText="Ok Simgesi"  
          >
          </LinkComponent>
        </div>
        <div className="main-component reverse">
        <h1 className='baslik'>ACSDAYS</h1>
        <ImageCarousel images={images_acs}  />
          <TextComponent 
            title="ACSDAYS"
            description="Ankara Computer Science Days ACSDays, ulusal çapta bilgisayar bilimleri üzerine düzenlenen konferanslar ve eğitimler etkinliğidir. İki gün boyunca süren etkinlikte, bilgisayar bilimlerinin farklı konuları ile ilgili eş zamanlı konferanslar ve eğitimler yer almaktadır. Ülkemizde nadir düzenlenen bilgisayar bilimleri etkinliklerinden biri olan ACSDays'in en büyük hedefleri, ülkemizde bilgisayar bilimleri bölümlerinde okuyan veya bilgisayar bilimlerine ilgi duyan bireylerin, kendilerini teknik alanda geliştirebileceği ve network kazanabileceği bir ortam yaratmak ve bunun yanında da bilgisayar bilimleri alanındaki büyük etkinlik açığını kapatmaktır."
          />
        </div>
        <div  className="text-link"  >
         <LinkComponent 
          link={"https://acsdays.acmhacettepe.com/"} 
          imgSrc={`${process.env.PUBLIC_URL}/ok_resmi.jpeg`} 
          altText="Ok Simgesi"  
          >
          </LinkComponent>
        </div>
        <div className="main-component">
        <h1 className='baslik'>LİDERLİK KAMPI </h1>
          <ImageCarousel images={images_Liderlik_kampi}  />
          <TextComponent 
            title="LİDERLİK KAMPI" 
            description="Her sene düzenli olarak gerçekleştirilen Liderlik Kampı, ACM Hacettepe Öğrenci Topluluğu’nun en önemli etkinliklerinden biridir. Bu etkinliğimizde, gelecek senelerde yönetim kurulumuzda görev almak veya kendisini liderlik açısından geliştirmek isteyen aktif üyelerimizin katılımıyla Ankara dışındaki bir kamp-eğitim merkezine gidilir. Doğayla iç içe olan, dış dünyayla olan tüm bağlantının kesildiği bu yerde 2 gün boyunca aktif üyelerimizin samimi bir ortamda güzel vakit geçirmeleri ve oynanan eğlenceli oyunlarla birbirlerini daha iyi tanıyarak kaynaşmaları, iletişimlerini güçlendirmeleri ve kendilerini geliştirmeleri hedeflenmektedir. Aktif üyelerimizin etkinlik süresince oynadığı kişisel gelişime dayalı interaktif oyunlar ile iletişim, liderlik, takım çalışması, zaman-kriz yönetimi gibi yetenekleri sınanır. Oyunlar süresince topluluğumuzda senelerini geçirmiş tecrübeli danışma kurulu üyelerimiz, oynanan oyunları gözlemleyip yönetim kurulu adayları hakkında detaylı bir rapor çıkartır. Mülakatlarda bu rapor önemli bir yer tutar. Dostluk ve arkadaşlık bağlarının güçlendiği, ACM Hacettepe’yi aile yapan bu etkinlik; topluluğumuzun en önemli etkinliklerindendir."
          />
        </div>
        <div  className="text-link-reverse"  >
         <LinkComponent 
          imgSrc={`${process.env.PUBLIC_URL}/ok_resmi.jpeg`} 

          altText="Ok Simgesi" 
          link="https://www.instagram.com/p/C7Wey17tQR5/?igsh=c3U0MDB2Z3JsbHpu" 

          >
          </LinkComponent>
        </div>
        <div className="main-component reverse">
        <h1 className='baslik1'> TEKNİK DERSLER VE SÖYLEŞİLER </h1>
        <ImageCarousel images={images_teknik}  />
          <TextComponent 
            title="TEKNİK DERSLER VE SÖYLEŞİLER"
            description="Bilişim alanında ün salmış eğitmenler ile bu alanda kendini geliştirmeyi amaçlayan öğrencileri çeşitli etkinik, dersler ve söyleşiler ile bir araya getirmeyi hedefleyen etkinliklerdir."
          />
        </div>
        <div  className="text-link"  >
         <LinkComponent   
          imgSrc={`${process.env.PUBLIC_URL}/ok_resmi.jpeg`} 

          altText="Ok Simgesi" 
          link="https://www.instagram.com/p/C7Wey17tQR5/?igsh=c3U0MDB2Z3JsbHpu" 

          >
          </LinkComponent>
        </div>
        <div className="main-component">
        <h1 className='baslik'> TEKNİK GEZİLER </h1>
          <ImageCarousel images={images_teknik_gezi}  />
          <TextComponent 
            title="TEKNİK GEZİLER" 
            description="Üyelerimize profesyonel çalışma ortamlarını gösterebilmek ve şirketler ile etkileşim halinde bulunarak iş hayatlarına bakışlarını geliştirmek adına belirli profesyonel ve kurumsal firmalara teknik geziler düzenlemekteyiz."
          />
        </div>
        <div  className="text-link-reverse"  >
         <LinkComponent 
          imgSrc={`${process.env.PUBLIC_URL}/ok_resmi.jpeg`} 

          altText="Ok Simgesi" 
          link="https://www.instagram.com/p/C4VvMx-NCOk/?igsh=MTdjMmU4OGp0Z2Fubg==" 
          >
          </LinkComponent>
        </div>
      </div>

      </div>
    );
  }
}

export default Events;

