import HeroSection from "../components/Hero";
import MainPageAnon from "../components/MainPageAnon";      
import MainPageHero from "../components/MainPageHero";
import MainPageLast from "../components/MainPageLast";
import MainPageSlider from "../components/MainPageSlider";
import MainPageYoutube from "../components/MainPageYoutube";
import photo from "../assets/home_glow.svg";


function Home() {

    return (
        <>
    <HeroSection title="ACM HACETTEPE" p="Association for Computing Machinery" photo={photo} />
    <br></br>
    <MainPageAnon/>
    <br/>
    <br/>
    <MainPageYoutube></MainPageYoutube>
    <br/>
    <br/>
    <MainPageLast/>
   
        </>

    
)}

export default Home;