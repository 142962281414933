import React, { useState } from 'react';
import '../styles/contact.css'; 

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    message: ''
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus('Mesaj gönderiliyor...');

    const form = e.target;
    const formData = new FormData(form);
    
    fetch("https://getform.io/f/bdryponb", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        console.log(response);
        if (response.ok) {
          setStatus('Mesaj başarıyla gönderildi.');
        } else {
          setStatus('Mesaj gönderilemedi, lütfen tekrar deneyin.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setStatus('Mesaj gönderilemedi, lütfen tekrar deneyin.');
      });
  };

  return (
    <form 
      className="form-container" 
      onSubmit={handleSubmit} 
      action="https://getform.io/f/bdryponb" 
      method="POST"
    >
      <div className="form-row">
        <input type="text" name="name" placeholder='Ad' value={formData.name} onChange={handleChange} required />
        <label></label>
        <input type="text" name="surname" placeholder='Soyad' value={formData.surname} onChange={handleChange} required />
      </div>

      <div className="form-row">
        <input type="email" name="email" placeholder='Eposta' value={formData.email} onChange={handleChange} required />
        <label></label>
        <input type="tel" name="phone" placeholder='Telefon' value={formData.phone} onChange={handleChange} />
      </div>

      <div className="form-row full-width">
        <textarea name="message" placeholder='Mesajınız' value={formData.message} onChange={handleChange} required></textarea>
      </div>

      <button type="submit">Gönder</button>

      {status && <p>{status}</p>}
    </form>
  );
};

export default ContactForm;