import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

const MainPageLast = () => {
  return (
    <div className=" w-full flex md:flex-row flex-col">
        <div className='relative md:w-1/2 w-full h-inherit md:order-1 order-2 max-md:h-[35px] md:flex items-end md:justify-end '>
            <p className='ml-[20%] w-[65%] absolute md:relative bottom-0 md:top-0 text-[8px] md:text-2xl text-left font-semibold'>Her yıl yenisini dijital olarak yayınladığımız dergimizin son sayısını okuyabilirsiniz.</p>
        </div>
      {/* Group 37 */}
      <div className=" flex flex-col md:flex-col md:w-1/2 w-full items-center justify-center md:order-2 order-1">
        
        {/* MORSPEKTİF */}
        <div className="w-[80%] md:w-[60%] h-[87.84px] flex flex-col justify-center items-center text-center">
          <div className="flex">
            <h1 className="text-5xl font-bold text-[#7711B6] inline">MOR</h1>
            <h1 className="text-5xl font-bold text-[#1B1C1E] inline">SPEKTİF</h1>
          </div>
          <p className="text-lg font-medium">Dergimizin son sayısına göz atın!</p>
        </div>

        {/* Vectors */}
        <div className="bg-[#1B1C1E]"></div>
        <div className="bg-[#1B1C1E]"></div>
        <div className="bg-[#1B1C1E]"></div>
        <div className="bg-[#1B1C1E]"></div>
        <div className="bg-[#1B1C1E]"></div>
        <div className="bg-[#1B1C1E]"></div>
        <div className="bg-[#1B1C1E]"></div>
        <div className="bg-[#7711B6]"></div>
        <div className="bg-[#7711B6]"></div>
        <div className="bg-[#7711B6]"></div>


      {/* Image with shadow */}
      <div className="relative w-[65%] md:w-[40%] aspect-[589/833] shadow-lg rounded-[14px]">
        <img src={require('../assets/morspektif.png')} alt="Image" className="w-full h-full object-cover rounded-[14px]" />
       <div className="absolute bottom-[50%] left-[-5.5%] w-[15%] aspect-square rounded-full bg-gradient-to-r from-[#7711B6] to-[#BB63DF] flex items-center justify-center cursor-pointer rotate-[-45deg]">
        <FaArrowRight className="text-white text-2xl" />
       </div>
      </div>
      </div>
    </div>
  );
};

export default MainPageLast;