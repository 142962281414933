import React, { Component } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; 

export default class MainPageAnon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
    };
    this.slides = [
      {
        imgSrc: require('../assets/syn.JPG'),
        title: 'Senin Yerin Neresi?',
        description: 'Senin Yerin Neresi çok yakında! Takipte kal, kaçırma!',
      },

      {
        imgSrc: require('../assets/MorG.jpg'),
        title: 'Mor Geyik Teknik',
        description: 'Mor Geyik Teknik etkinliğimizde Blockchain ve Metaverse hakkında konuşmasıyla Devrim Danyal\'ı ağırlıyoruz!',
      },
      {
        imgSrc: require('../assets/acs.png'),
        title: 'ACS Days',
        description: 'Bu yıl 7.sini düzenleyeceğimiz etkinliğimizde sektörün önde gelen şirketlerini ve isimlerini ağırlıyor olacağız!',
      },
    ];
  }

  componentDidMount() {
    this.autoSlide = setInterval(() => {
      this.setState({
        currentSlide: (this.state.currentSlide + 1) % this.slides.length,
      });
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.autoSlide);
  }

  goToNextSlide = () => {
    this.setState({
      currentSlide: (this.state.currentSlide + 1) % this.slides.length,
    });
  };

  goToPreviousSlide = () => {
    this.setState({
      currentSlide: (this.state.currentSlide - 1 + this.slides.length) % this.slides.length,
    });
  };

  render() {
    const { currentSlide } = this.state;
    const { imgSrc, title, description } = this.slides[currentSlide];

    return (
      <div className="w-full">
        
        {/* Duyurular Başlığı */}
        <h1 className="font-poppins text-left ml-[8%] mt-[50px] font-semibold text-[5.2vw] text tracking-tight text-[#1B1C1E]">
          DUYURULAR
        </h1>

        {/* Kartlar ve Slider */}
        <div className="w-full flex flex-wrap justify-center pb-4 space-x-3 md:flex-row sm:space-x-6">
          
          {/* Kart 1 */}
          <div className="relative w-[40%] md:w-[18.75%]  md:ml-0 order-4 md:order-1  bg-black rounded-xl aspect-[3/4] items-center justify-center flex min-h-[250px]">
            <img src={require('../assets/mainPageArge.png')} alt="Arge" className="w-[67%] object-contain" />
          </div>

          {/* Kart 2 */}
          <div className="relative w-[40%] md:w-[18.75%] order-5 md:order-2  bg-acmAnonColorBack rounded-xl aspect-[3/4] items-center justify-center flex min-h-[250px]">
            <img src={require('../assets/kff.png')} alt="Kristal Geyik" className="w-full h-full object-cover rounded-xl" />
          </div>

          {/* Slider */}
          <div className="relative md:w-[45%] mb-5 mt-2 order-3 w-[85%] pr-6 rounded-2xl overflow-hidden min-h-[250px] ">
            
            {/* Slider Resmi */}
            <div>
            <div className=" w-full h-full ">
              {this.slides.map((slide, index) => (
                <img
                  key={index}
                  src={slide.imgSrc}
                  alt={slide.title}
                  className={`w-full h-full object-cover rounded-2xl transition-opacity duration-500 ease-in-out ${currentSlide === index ? 'opacity-100' : 'opacity-0'} absolute`}
                />
              ))}
            </div>

            {/* Sol Ok */}
            <div className="absolute left-3 top-[50%] translate-y-[-50%] bg-white p-[1.5%] rounded-full shadow-md cursor-pointer" onClick={this.goToPreviousSlide}>
              <FaChevronLeft className="text-[#B902FF] text-xl" />
            </div>

            {/* Sağ Ok */}
            <div className="absolute right-3 top-[50%] translate-y-[-50%] bg-white p-[1.5%] rounded-full shadow-md cursor-pointer" onClick={this.goToNextSlide}>
              <FaChevronRight className="text-[#B902FF] text-xl" />
            </div>

            {/* Alt İçerik Kapsayıcı */}
            <div className="absolute bottom-[1.5%] w-[98%] left-[1%] bg-white bg-opacity-50 backdrop-blur-lg text-black rounded-2xl">
              <h2 className="text-[4vw] md:text-[1.7vw] text-left pl-[5%] md:pl-[2.5%] font-semibold text-center md:text-left w-full md:w-[70%]">{title}</h2>
              <p className="text-[3.5vw] md:text-[1.05vw] text-left pl-[5%] md:pl-[2.5%] w-full md:w-[70%] mt-2 text-gray-700 text-center md:text-left">
                {description}
              </p>
            </div>

            {/* Dot Navigasyonu */}
            <div className="absolute right-[0%] bottom-[4%] w-[20%] flex justify-center space-x-2">
              {this.slides.map((_, index) => (
                <div key={index} className={`w-[10px] h-[10px] rounded-full ${currentSlide === index ? 'bg-[#B902FF] w-[27px]' : 'bg-white bg-opacity-50'}`}></div>
              ))}
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
