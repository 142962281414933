import React from 'react';
import '../index.css';
import HeroSection from '../components/Hero';
import photo from '../assets/annouceements.svg';

const Card = ({ title, content }) => (
  <div className="bg-gradient-to-b from-[#5F2875] to-[#B14BDB] pt-aspect-ratio-67 rounded-custom flex items-center justify-center img">
    <div className="bg-[#FFFFFF] opacity-75 rounded-custom p-4 w-[96%] text-left shadow-normal" style={{ marginBottom: '2%'}}>
      <h2 className="text-xl">{title}</h2>
      <p className="text-xs">{content}</p>
    </div>
  </div>
);

const Announcements = () => {
  const announcementData = [
    { title: "Başlık 1", content: "Lorem ipsum dolor sit amet consectetur. Lacus pharetra ante viverra aenean lorem auctor tempor. Ut auctor odio malesuada vestibulum." },
    { title: "Başlık 2", content: "Lorem ipsum dolor sit amet consectetur. Lacus pharetra ante viverra aenean lorem auctor tempor. Ut auctor odio malesuada vestibulum." },
    { title: "Başlık 3", content: "Lorem ipsum dolor sit amet consectetur. Lacus pharetra ante viverra aenean lorem auctor tempor. Ut auctor odio malesuada vestibulum." },
    { title: "Başlık 4", content: "Lorem ipsum dolor sit amet consectetur. Lacus pharetra ante viverra aenean lorem auctor tempor. Ut auctor odio malesuada vestibulum." },
    { title: "Başlık 5", content: "Lorem ipsum dolor sit amet consectetur. Lacus pharetra ante viverra aenean lorem auctor tempor. Ut auctor odio malesuada vestibulum." },
    { title: "Başlık 6", content: "Lorem ipsum dolor sit amet consectetur. Lacus pharetra ante viverra aenean lorem auctor tempor. Ut auctor odio malesuada vestibulum." }
  ];

  return (
    <div>
      <HeroSection title="DUYURULAR" p="ACM Hacettepe" photo={photo} />
    <div className='App'>
    <div className="flex items-center justify-center min-h-screen">
      <div className="grid grid-cols-1 md:grid-cols-2 grid-rows-2 gap-4 w-full max-w-5xl px-4">
        {announcementData.map((item, index) => (
          <Card key={index} title={item.title} content={item.content} />
        ))}
      </div>
    </div>
    </div>
    </div>
  );
}

export default Announcements;
