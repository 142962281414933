/* ContactForm.js "Bu dosya mesajın gönderildiği dosya"*/
/* Web sayfasını genel component yapısının bulunduğu kısım */

import React from 'react';
import HeroSection from '../components/Hero'; 
import ContactForm from '../components/ContactForm'; 
import Map from '../components/Map'; 
import '../styles/contact.css';
import photo from '../assets/contact.svg';


const Iletisim = () => {
  return (
    <div>
    <HeroSection title="İLETİŞİM" p="ACM Hacettepe" photo={photo} />
    <div className="cont">
      
      <div className="bizimle-iletisim">
        <h2>BİZİMLE İLETİŞİME GEÇİN</h2>
        <div className="subtitle">Her türlü tavsiye, öneri, şikayet</div>
      </div>
      <div className="contact-section">
        <div className="contact-form">
          <ContactForm />
        </div>
        <div className="contact-map">
          <Map />
        </div>
      </div>
    </div>
    </div>
  );
};

export default Iletisim;



/* map.js */



/* css dosyası */
