import React, { useEffect, useState } from 'react';
import '../styles/Hero.css';


function HeroSection(props) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {

    setIsVisible(true);
    console.log(window.location.pathname)

    return () => setIsVisible(false);
  }, []);


  return (


    <section className='hero'>
      <div className="header relative h-[calc(50vh + 50vw)] overflow-hidden">
      <img src={props.photo} className=' -z-100 pointer-events-none absolute translate-y-[-50%] mobile-bigger'/>
        <div className='flex flex-col justify-center align-middle'>
          <p className={isVisible ? 'text-animate-in' : 'text-animate-out z-10'}>
            {props.p}
          </p>
          <h1 className={isVisible ? 'text-animate-in' : 'text-animate-out z-10'}>
            {props.title}
          </h1>
          <a href="/uyeol" className={ `mt-3  bg-gradient-to-r from-[#5F2875] to-[#B14BDB] text-white font-medium text-sm py-2 px-4 rounded-md mx-auto z-10 ${window.location.pathname == '/' ? '' : 'opacity-0 pointer-events-none'}`}>
            Aramıza Katıl
          </a>
        </div>
      </div>
    </section >
    
  );
}
export default HeroSection;
