import { animateScroll as scroll } from 'react-scroll';
import { useEffect } from 'react';

const Scroll = () => {
    useEffect(() => {
        const isMobile = window.innerWidth <= 768; // Set your mobile breakpoint (e.g., 768px)
    
        if (isMobile) {
          const scrollTimeout = setTimeout(() => {
            scroll.scrollTo(900, {
                duration: 1300,  // The time (in ms) the animation takes
                smooth: 'easeInOutQuart'  // Custom smooth scrolling animation
              });
            }, 2300);  
       // 3-second delay
    
          return () => clearTimeout(scrollTimeout);  // Clean up the timeout on unmount
        }

        else {
            const scrollTimeout = setTimeout(() => {
                window.scrollTo({
                  top: 750,  // Scroll to 300px down
                  behavior: 'smooth'  // Smooth scroll
                });
              }, 2300);  // 3-second delay
        
              return () => clearTimeout(scrollTimeout);  // Clean up the timeout on unmount
        }
      }, []);
    
      return (
        <div>
        </div>
      );
    };

export default Scroll;
