import React, { Component } from 'react'

export default class MainPageYoutube extends Component {
  // Dinamik video verilerini state içinde tutuyoruz.
  state = {
    videos: [
      {
        id: 1,
        channelImage: 'https://yt3.googleusercontent.com/t0fS-v4wHvpr3tSElxzCFsDnQgQ0BfpzCmnR72utK_QcsKFY4egEsK0C_-cbQPQUOC_EKmfb=s160-c-k-c0x00ffffff-no-rj',
        videoTitle: 'Hacettepe Üniversitesi Beytepe Kampüs Turu 2023',
        videoThumbnail: 'https://i.ytimg.com/vi/5mNBC4Gfy7U/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAONEjSYvkO-XHy1Omi-B4vV8bFXw',
        videoLink: 'https://youtu.be/5mNBC4Gfy7U?si=3tt14lmkjBIwXjb9'
      },
      {
        id: 2,
        channelImage: 'https://yt3.googleusercontent.com/t0fS-v4wHvpr3tSElxzCFsDnQgQ0BfpzCmnR72utK_QcsKFY4egEsK0C_-cbQPQUOC_EKmfb=s160-c-k-c0x00ffffff-no-rj',
        videoTitle: 'Hacettepe Üniversitesi Yapay Zeka Mühendisliği Bölüm Tanıtımı',
        videoThumbnail: 'https://i.ytimg.com/vi/F-P3mIhznGI/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC_hid6iFeWdx5WRXk_JMLjUdbs9Q',
        videoLink: 'https://youtu.be/F-P3mIhznGI?si=Z05O8s2AWqAivqES'
      },
      {
        id: 3,
        channelImage: 'https://yt3.googleusercontent.com/t0fS-v4wHvpr3tSElxzCFsDnQgQ0BfpzCmnR72utK_QcsKFY4egEsK0C_-cbQPQUOC_EKmfb=s160-c-k-c0x00ffffff-no-rj',
        videoTitle: 'HACETTEPELİYE SORDUK l BİLGİSAYAR MÜHENDİSLİĞİ',
        videoThumbnail: 'https://i.ytimg.com/vi/OUDvp04cAMM/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBL-75hs4OrvVSXcOF1OUGIjmxSjg',
        videoLink: 'https://youtu.be/OUDvp04cAMM?si=DfBLCUvxPlZWNeUI'
      },
      {
        id: 4,
        channelImage: 'https://yt3.googleusercontent.com/t0fS-v4wHvpr3tSElxzCFsDnQgQ0BfpzCmnR72utK_QcsKFY4egEsK0C_-cbQPQUOC_EKmfb=s160-c-k-c0x00ffffff-no-rj',
        videoTitle: 'Hacettepe Üniversitesi Beytepe Kampüs Turu 2024',
        videoThumbnail: 'https://i.ytimg.com/vi/kEqMCq1YUBs/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCplO8Pj-mzt1RCB9ZZcHbSlWIQTQ',
        videoLink: 'https://youtu.be/kEqMCq1YUBs?si=B1ntEiFRtahAe-sQ'
      },
    ],
    isSmallScreen: false,
  };

  updateScreenSize = () => {
    const isSmallScreen = window.innerWidth <= 1023;
    this.setState({ isSmallScreen });
  };

  componentDidMount() {
    this.updateScreenSize();
    window.addEventListener('resize', this.updateScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScreenSize);
  }

  render() {
    const { videos, isSmallScreen } = this.state;

    // Eğer ekran küçükse sadece id'si 1 ve 2 olanları göster
    const displayedVideos = isSmallScreen ? videos.filter(video => video.id === 1 || video.id === 2) : videos;

    return (
      <div className='w-full flex flex-col'>
        <div className='w-full pl-6 '>
          <img className='w-[40%] md:ml-[7.5%] md:w-[10%] aspect-[3/1]' src={require('../assets/MainPageYoutubePic.png')} loading='lazy'></img>
        </div>
        <div className='ml-[7.5%] w-[85%] h-[4px] bg-[#B14BDB]'>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 p-4 w-[85%] ml-[7.5%]">
          {displayedVideos.map((video) => (
            <div key={video.id} className="border rounded-lg overflow-hidden shadow-lg">
              <img src={video.videoThumbnail} alt={video.videoTitle} className="w-full aspect-[9/5] object-cover" loading="lazy" />
              <div className="p-4 flex items-center">
                <img src={video.channelImage} alt="Channel" className="w-10 h-10 rounded-full mr-4" loading='lazy' />
                <a href={`${video.videoLink}`}> <h4 className="text-lg font-semibold">{video.videoTitle}</h4></a>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}