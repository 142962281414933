import '../index.css'; // Tailwind CSS'i uygulamak için
import logo from '../assets/acm-blue.svg';
import stant from '../assets/stant.jpg';
import HeroSection from '../components/Hero';
import photo from '../assets/whoarewe.svg';


const Whoarewe = () => {
    return (
        <div>
            <HeroSection title="BİZ KİMİZ" p="ACM Hacettepe" photo={photo} />
        <div className='Whoarewe'>
            <div className="grid grid-cols-[30vw_1fr] gap-10g p-10p mx-auto items-center">
                <img className="w-full h-auto" src={logo} alt="ACM Logo" loading='lazy' />
                <div className="max-w-[42vw] text-left">
                    <h2 className="text-3xl sm:text-5xl my-1">ACM Nedir?</h2>
                    <p className="text-base sm:text-2xl m-0">ACM (Association for Computing Machinery), bilgisayar mühendisliği bilimini bir araya getirmek için 1947'de kurulmuş ve gün geçtikçe büyüyerek dünya çapında tanınan bir kuruluştur. Merkezi New York şehrinde olan ACM; dünya genelinde çeşitli şubeleri, profesyonel kulüpleri, özel araştırma gruplarını ve öğrenci kollarını bünyesinde barındırmaktadır.</p>
                    <p className="text-base sm:text-2xl m-0">ACM, mesleki akademik yayın ve toplantı faaliyetlerinin yanı sıra bilgisayar bilimlerinin Nobel'i sayılan Turing Ödülü'nü veren kuruluş olarak da bilinir. Dünya genelini kapsayan üyelik sistemi sayesinde bilişim teknolojisinin çeşitli alanları ile uğraşan profesyonellere ve öğrencilere kaynak sağlamaktadır.</p>
                </div>
            </div>
            <div className="grid grid-cols-[1fr_32vw] gap-10g p-10p mx-auto items-center">
                <div className="max-w-[42vw] text-left">
                    <h2 className="text-3xl sm:text-5xl my-2">ACM Hacettepe Öğrenci Topluluğu</h2>
                    <p className="text-base sm:text-2xl m-0">ACM Hacettepe Öğrenci Topluluğu; 2010 yılında kurulmuş olup ACM'in Türkiye'de bulunan 13 öğrenci kolundan biridir. Topluluğumuz kurulduğu günden bu yana her geçen gün artan üye sayısıyla Hacettepe Üniversitesinin en büyük topluluklarından biri haline gelmiştir.</p>
                    <br />
                    <p className="text-base sm:text-2xl m-0">ACM Hacettepe Öğrenci Topluluğu olarak amacımız teknik ve sosyal alanda yapılan etkinliklerle öğrencilerimizi her iki alanda da bilgi sahibi olmuş, gelişmiş bir birey haline getirmektir. Topluluğumuzun en önemli görevi; öğrencilerimizin meslek hayatı ile ilgili sorularına cevap bulacağı seminerler düzenlemek, iş hayatlarında onlara yardımcı olacak teknik bilgiler vermek ve bu bilgileri teknik geziler ile desteklemektir. Ayrıca yapılan sosyal etkinliklerle öğrencilerimizin sosyal yönü gelişmiş bireyler olmasını hedefliyoruz.</p>
                </div>
                <div className="w-auto h-full overflow-hidden">
                    <img className="w-full h-full object-cover" src={stant} alt="Stant" loading='lazy' />
                </div>
            </div>
        </div>
        </div>
    );
}

export default Whoarewe;
