import React from 'react';
import '../styles/events.css';

const TextComponent = ({ title,description, link }) => {
  return (
    <div className="text-component">
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
  );
};

export default TextComponent;